import * as React from 'react';

import type { HeadFC } from 'gatsby';
import { SEO } from '../components/SEO';

const IndexPage = () => {
	return <div></div>;
};

export default IndexPage;
